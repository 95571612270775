import React, { useEffect, useState } from 'react';
import './Card.css'; // CSS styles for the card
import { WebsocketProvider } from 'y-websocket';
import { fetchCardData, saveCardData, syncDoc, renderComponent } from 'qwanyx-core';

export const Card = ({
    cardId,
    isCollaborative = false,
    showHeader = true,
    showFooter = false,
    headerContent = null,
    footerContent = null,
    children,
    className = '',
    style = {},
    ...props
}) => {
    const [cardData, setCardData] = useState({});
    const [yMap, setYMap] = useState(null);

    useEffect(() => {
        let isMounted = true;

        if (isCollaborative && cardId) {
            // Initialize Y.js synchronization
            const yMapInstance = syncDoc.getMap(`card-${cardId}`);
            const provider = new WebsocketProvider('wss://your-websocket-server', `card-${cardId}`, syncDoc);

            const updateCardData = () => {
                if (isMounted) setCardData(yMapInstance.get('data') || {});
            };

            yMapInstance.observe(updateCardData);
            setYMap(yMapInstance);

            // Clean up on unmount
            return () => {
                isMounted = false;
                yMapInstance.unobserve(updateCardData);
                provider.disconnect();
            };
        } else {
            // Fetch data from database or local storage
            fetchCardData(cardId).then((data) => {
                if (isMounted) setCardData(data || {});
            });
            return () => {
                isMounted = false;
            };
        }
    }, [cardId, isCollaborative]);

    // Function to update card data
    const updateCardData = (newData) => {
        if (isCollaborative && yMap) {
            yMap.set('data', newData);
        } else {
            // Update local state and save to database
            setCardData(newData);
            saveCardData(cardId, newData);
        }
    };

    // Render function for nested components based on cardData
    const renderContent = () => {
        if (cardData && cardData.children) {
            return cardData.children.map((child, index) => (
                <React.Fragment key={index}>
                    {renderComponent(child)}
                </React.Fragment>
            ));
        }
        return children;
    };

    return (
        <div className={`card ${className}`} style={style} {...props}>
            {showHeader && (
                <header className="card-header">
                    {headerContent || cardData.headerContent}
                </header>
            )}
            <main className="card-content">
                {renderContent()}
            </main>
            {showFooter && (
                <footer className="card-footer">
                    {footerContent || cardData.footerContent}
                </footer>
            )}
        </div>
    );
};
