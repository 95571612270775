import React from 'react';
import { Card } from 'qwanyx-core'; // Adjust the import path based on your folder structure

export const QwanyxLandingPage = () => {
  // Define the card data
  const cardData = {
    cardId: 'basicCard1',
    isCollaborative: false,
    headerContent: 'Welcome to Qwanyx',
    footerContent: 'Footer Information',
    children: [
      {
        type: 'Text',
        props: {
          content: 'This is a simple card for your HyperCard-style editor.',
        },
      },
    ],
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Card
        cardId={cardData.cardId}
        isCollaborative={cardData.isCollaborative}
        showHeader={true}
        showFooter={true}
        headerContent={cardData.headerContent}
        footerContent={cardData.footerContent}
      >
        {cardData.children.map((child, index) => (
          <React.Fragment key={index}>
            {child.type === 'Text' && <p>{child.props.content}</p>}
          </React.Fragment>
        ))}
      </Card>
    </div>
  );
};
